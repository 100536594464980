import React from 'react'
import styled from 'styled-components'
import { useContext } from 'react'
import { GeoContext } from '@/context/geoContext'
import { getCompanyNameByCountry } from '@/lib/utils/countryValues'

const StyledCopyright = styled.p<{ $minimal: boolean; $centered: boolean }>`
  flex-grow: 2;
  width: 100%;
  max-width: 960px;
  margin: 16px 0 0 0;
  padding: 24px 0;
  text-align: left;
  font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
  line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
  font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
  border-top: 1px solid ${(props) => props.theme.v2.onSurface.subdued.border};

  ${(props) => props.$minimal && !props.$centered && 'border-top: 0; padding-left: 40px;'}
  ${(props) => props.$centered && 'border-top: 0; text-align: center; padding-left: 0; max-width: unset;'}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    color: ${(props) => props.theme.v2.onSurface.subduedText};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.v2.typography.label.l.fontSize};
    line-height: ${(props) => props.theme.v2.typography.label.l.lineHeight};
    margin-top: 8px;
    padding: 16px 0;
    ${(props) => props.$minimal && !props.$centered && 'padding-left: 24px;'}
    ${(props) => props.$centered && 'padding-left: 0;'}
  }
`

type CopyrightProps = {
  minimal?: boolean
  centered?: boolean
}

export const Copyright: React.FC<CopyrightProps> = ({ minimal = false, centered = false }) => {
  const { country } = useContext(GeoContext)
  const year = new Date().getFullYear()

  return (
    <StyledCopyright $centered={centered} $minimal={minimal}>
      &copy;{year} {getCompanyNameByCountry(country)}
    </StyledCopyright>
  )
}
